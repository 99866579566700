import format from "date-fns/format";

import { SubscriptionMessageFragment } from "@/core/Subscription/graphql/subscriptions/messaging.generated";
import { Messaging_Api_ChannelEnrichedOutput } from "@/graphql-schema-types.generated";
import { isValidDate } from "@/lib/is-valid-date";

export const groupMessagesByDate = ({
  showAll = false,
  notificationChannels = [],
  history = {},
}: {
  showAll?: boolean;
  notificationChannels: Array<
    Pick<Messaging_Api_ChannelEnrichedOutput, "channel">
  >;
  history: { [channel: string]: SubscriptionMessageFragment[] | undefined };
}) => {
  const groupMsgs: SubscriptionMessageFragment[] = [];
  for (const channel of notificationChannels) {
    const itemsFromHistory = history[channel.channel];
    if (itemsFromHistory) {
      groupMsgs.push(...itemsFromHistory);
    }
  }

  groupMsgs.sort((a, b) => {
    const timeA = new Date(a.created).getTime();
    const timeB = new Date(b.created).getTime();

    if (timeA > timeB) {
      return -1;
    } else if (timeA < timeB) {
      return 1;
    } else {
      return a.text >= b.text ? -1 : 1;
    }
  });

  const msgObject = {};

  for (const item of groupMsgs) {
    if ((showAll || !item.seen) && isValidDate(item.created)) {
      const created = item.created;
      const key = format(new Date(created), "yyyy.MMM.dd");

      msgObject[key] = (msgObject[key] ?? []).concat(item);
    }
  }

  return msgObject;
};
