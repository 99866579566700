import React, { RefObject, useCallback, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import cn from "classnames";
import useClickOutside from "click-outside-hook";
import { useStore } from "effector-react";

import {
  Account24Icon,
  Chat24Icon,
  Dashboard24Icon,
  Logout24Icon,
  Notifications24Icon,
} from "@/components/icons";
import { MobileNotifications } from "@/components/notifications/MobileNotifications";
import { Button } from "@/components/ui";
import { Routes } from "@/constants";
import { useUserInfo } from "@/core/Auth/hooks";
import { $chatStores } from "@/core/Chat/effector";
import { ChatInitializedStore } from "@/core/Chat/effector/chat-types";
import { onChatError } from "@/core/Chat/effector/errors";
import { onSetModePreview } from "@/core/Chat/effector/mode";
import { AuthUrlService } from "@/lib/services";
import { getMediaUrl } from "@/lib/utils";
import { Button as OldButton } from "@/ui/components";

import { AccountDropdownProps } from "./types";

import styles from "./styles.module.scss";

export const AccountDropdown = ({
  firstName,
  avatar,
}: AccountDropdownProps) => {
  const router = useRouter();
  const me = useUserInfo();
  const {
    $chatUnreadSystemNotificationsCount: unreadCountNotifications,
    $chatInitialized: chatInitialized,
    $chatUnreadCount: unreadCount,
  } = useStore($chatStores);
  const [isOpen, setOpen] = useState(false);
  const [isOpenedNotifications, setIsOpenedNotifications] = useState(false);
  const ref = useClickOutside(() =>
    setOpen(false)
  ) as RefObject<HTMLDivElement>;
  const handleOpen = useCallback(() => {
    setOpen((a) => !a);
  }, []);

  const handleClickChat = useCallback(
    (e) => {
      e.preventDefault();

      if (chatInitialized === ChatInitializedStore.DONE) {
        onSetModePreview();
      } else {
        onChatError("Chats are temporary unavailable");
      }
    },
    [chatInitialized]
  );

  return (
    <>
      <div ref={ref} className={styles.container}>
        {me?.id == null ? (
          <Link
            href={AuthUrlService.getCreateAccountUrl(router?.asPath)}
            prefetch={false}
          >
            <a rel="nofollow">
              <Button
                className={styles.buttonJoin}
                shape="rounded"
                size="md"
                theme="primary"
                variant="outlined"
              >
                Join
              </Button>
            </a>
          </Link>
        ) : null}
        <OldButton
          classNameButton={cn(styles.button, {
            [styles.opened]: isOpen,
            [styles.buttonHidden]: me?.id == null,
          })}
          classNameIcon={styles.classNameIcon}
          data-testid="header-account-icon"
          iconBefore={
            avatar ? (
              <img
                alt={firstName ?? ""}
                className={styles.avatar}
                src={getMediaUrl(avatar)}
              />
            ) : (firstName ? (
              <div className={cn(styles.avatar, styles.avatarPlaceholder)}>
                {firstName.slice(0, 1)}
              </div>
            ) : (
              <div className={styles.avatar}>
                <div className={styles.avatarIcon}>
                  <Account24Icon />
                </div>
              </div>
            ))
          }
          onClick={handleOpen}
          variant="light"
        >
          {firstName && <div className={styles.name}>{firstName}</div>}
          {unreadCountNotifications + unreadCount > 0 && (
            <ins className={cn(styles.unread, styles.unreadCommon)}>
              {unreadCountNotifications + unreadCount}
            </ins>
          )}
        </OldButton>
        <div
          className={cn(styles.dropDown, {
            [styles.visible]: isOpen,
          })}
        >
          {me?.id != null ? (
            <>
              <Link href={Routes.Dashboard} prefetch={false}>
                <a className={styles.dropDownItem}>
                  <Dashboard24Icon />
                  <span className={styles.dropDownItemText}>Dashboard</span>
                </a>
              </Link>
              <div
                className={cn(styles.dropDownItem, styles.dropDownItemMessage)}
                onClick={handleClickChat}
                role="button"
                tabIndex={0}
              >
                <Chat24Icon />
                <span className={styles.dropDownItemText}>Messages</span>
                {unreadCount > 0 && (
                  <ins className={styles.unread}>{unreadCount}</ins>
                )}
              </div>
              <div
                className={cn(styles.dropDownItem, styles.dropDownItemMessage)}
                onClick={() => setIsOpenedNotifications(true)}
                role="button"
                tabIndex={0}
              >
                <Notifications24Icon />
                <span className={styles.dropDownItemText}>Notifications</span>
                {unreadCountNotifications > 0 && (
                  <ins className={styles.unread}>
                    {unreadCountNotifications}
                  </ins>
                )}
              </div>
              <Link href="/profile" prefetch={false}>
                <a className={styles.dropDownItem}>
                  <Account24Icon />
                  <span className={styles.dropDownItemText}>Account</span>
                </a>
              </Link>
              <Link href={Routes.Logout} prefetch={false}>
                <a className={styles.dropDownItem}>
                  <Logout24Icon />
                  <span className={styles.dropDownItemText}>Log out</span>
                </a>
              </Link>
            </>
          ) : (
            <>
              <Link
                href={AuthUrlService.getLoginUrl(router?.asPath)}
                prefetch={false}
              >
                <a className={styles.dropDownItem} rel="nofollow">
                  <span>Sign in</span>
                </a>
              </Link>

              <Link
                href={AuthUrlService.getCreateAccountUrl(router?.asPath)}
                prefetch={false}
              >
                <a className={styles.dropDownItem} rel="nofollow">
                  <span>Join Mashroom</span>
                </a>
              </Link>
            </>
          )}
        </div>
      </div>

      {/* TODO Refactoring */}
      {me?.id && (
        <MobileNotifications
          isOpened={isOpenedNotifications}
          onClose={() => setIsOpenedNotifications(false)}
        />
      )}
    </>
  );
};
