import React from "react";

import { Skeleton } from "@/components/ui";

import styles from "./styles.module.scss";

export const UserActionsSkeleton = () => (
  <>
    <Skeleton className={styles.buttonFirst} />
    <Skeleton className={styles.buttonSecond} />
  </>
);
