import React, { FC } from "react";

import cn from "classnames";

import { ButtonProps } from "./types";

import styles from "./styles.module.scss";

export const Button: FC<ButtonProps> = ({
  children,
  className,
  variant = "transparent",
  ...rest
}) => (
  <button
    className={cn(styles.button, styles[variant], className)}
    type="button"
    {...rest}
  >
    {children}
  </button>
);
