import React, { RefObject, useCallback, useState } from "react";

import cn from "classnames";
import useClickOutside from "click-outside-hook";
import { useStore } from "effector-react";

import { Chat24Icon } from "@/components/icons";
import { useUserInfo } from "@/core/Auth/hooks";
import { $chatStores } from "@/core/Chat/effector";
import {
  ChatInitializedStore,
  ChatMode,
} from "@/core/Chat/effector/chat-types";
import { onChatError } from "@/core/Chat/effector/errors";
import { onSetModeHide } from "@/core/Chat/effector/mode";
import { setPresenceOnlineFx } from "@/core/Chat/effector/presense";
import { DropdownView as ChatDropdownView } from "@/features/private/chat-new/components/DropdownView";
import { dataLayerUserClickMessageButton } from "@/lib/dataLayersPush";
import formatFullName from "@/lib/formatters/fio";
import { ButtonIcon as OldButtonIcon } from "@/ui/components";

import styles from "./styles.module.scss";

export const ChatActions = () => {
  const {
    $chatInitialized: chatInitialized,
    $chatMode: mode,
    $chatUnreadCount: unreadCount,
  } = useStore($chatStores);
  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useClickOutside(() =>
    setOpen(false)
  ) as RefObject<HTMLDivElement>;

  const auth = useUserInfo();

  const handleClick = useCallback(() => {
    if (!isOpen) {
      dataLayerUserClickMessageButton({
        user_id: auth?.id as string,
        user_name: formatFullName(auth?.details),
      });
      void setPresenceOnlineFx();
    }

    setOpen(!isOpen);

    if (mode !== ChatMode.HIDDEN) {
      onSetModeHide();
    }

    if (chatInitialized !== ChatInitializedStore.DONE) {
      onChatError("Chats are temporary unavailable");
    }
  }, [auth?.details, auth?.id, chatInitialized, isOpen, mode]);

  if (auth?.id == null || chatInitialized !== ChatInitializedStore.DONE) {
    return null;
  }

  return (
    <div ref={ref} className={styles.container}>
      <OldButtonIcon
        classNameButton={cn({ [styles.opened]: isOpen })}
        data-testid="header-chat-icon"
        onClick={handleClick}
        variant="light"
      >
        <Chat24Icon />
        {unreadCount > 0 && <ins className={styles.unread}>{unreadCount}</ins>}
      </OldButtonIcon>

      <div
        className={cn(styles.dropDown, {
          [styles.visible]: isOpen,
        })}
      >
        <ChatDropdownView onChatSelect={() => setOpen(false)} />
      </div>
    </div>
  );
};
