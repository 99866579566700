import React, { RefObject, useCallback, useMemo, useState } from "react";

import Link from "next/link";

import cn from "classnames";
import useClickOutside from "click-outside-hook";
import { useStore } from "effector-react";

import { Notifications24Icon } from "@/components/icons";
import { useUserInfo } from "@/core/Auth/hooks";
import { $chatStores } from "@/core/Chat/effector";
import { useDeleteMessage } from "@/core/Chat/hooks/use-delete-message";
import { useFetchHistory } from "@/core/Chat/hooks/use-fetch-history";
import { useMarkMessageAsSeen } from "@/core/Chat/hooks/use-mark-message-seen";
import { ButtonIcon as OldButtonIcon } from "@/ui/components";

import { NotificationGroup } from "../NotificationGroup";
import { groupMessagesByDate } from "../utils";

import styles from "./styles.module.scss";

export const NotificationActions = () => {
  const markMessagesSeen = useMarkMessageAsSeen();
  const fetchHistory = useFetchHistory();
  const deleteMessages = useDeleteMessage();
  const {
    $chatUnreadSystemNotificationsCount: unreadCountNotifications,
    $chatChannels: channels,
    $chatHistory: history,
  } = useStore($chatStores);
  const auth = useUserInfo();
  const [isDropdownOpened, toggleDropdown] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    toggleDropdown(false);
  }, [toggleDropdown]);

  const ref = useClickOutside(() =>
    toggleDropdown(false)
  ) as RefObject<HTMLDivElement>;

  const notificationChannels = useMemo(
    () => channels.filter((chnl) => chnl.scope === "NOTIFICATION_SCOPE"),
    [channels]
  );

  const handleDropdownToggle = useCallback(() => {
    for (const chnl of notificationChannels) {
      if (
        history[chnl.channel] == null ||
        history[chnl.channel]!.length < unreadCountNotifications
      ) {
        void fetchHistory({
          channel: chnl.channel,
          scope: chnl.scope,
          limit: 100,
        });
      }
    }

    toggleDropdown((state) => !state);
  }, [fetchHistory, history, notificationChannels, unreadCountNotifications]);

  if (!auth?.id) {
    return null;
  }

  const lastMessagesGroupByDate = groupMessagesByDate({
    showAll: true,
    notificationChannels,
    history,
  });

  const groups = Object.keys(lastMessagesGroupByDate);

  return (
    <div ref={ref} className={styles.container}>
      <OldButtonIcon
        classNameButton={cn(styles.iconButton, {
          [styles.opened]: isDropdownOpened,
        })}
        data-testid="header-notif-icon"
        onClick={handleDropdownToggle}
        variant="light"
      >
        <Notifications24Icon />
        {unreadCountNotifications > 0 && (
          <ins className={styles.unread}>{unreadCountNotifications}</ins>
        )}
      </OldButtonIcon>

      <div
        className={cn(styles.dropDown, {
          [styles.visible]: isDropdownOpened,
        })}
      >
        <div className={styles.dropDownHeader}>
          {/* TODO: Ask Roman about this page. Do we really need it at the moment? */}
          <Link href="/profile/system-notifications">
            <a>Notifications</a>
          </Link>
        </div>
        <div className={styles.dropDownContent}>
          {groups.length > 0 &&
            groups.map((itemsByDate, index) => (
              <NotificationGroup
                key={itemsByDate}
                deleteMessages={deleteMessages}
                isFirst={index === 0}
                itemsByDate={itemsByDate}
                markMessagesSeen={markMessagesSeen}
                messages={lastMessagesGroupByDate}
                showAll
                toggleDropdown={closeModal}
              />
            ))}

          {groups.length === 0 && unreadCountNotifications === 0 && (
            <div className={styles.noItems}>You don't have any messages.</div>
          )}

          {groups.length === 0 && unreadCountNotifications > 0 && (
            <div className={styles.noItems}>
              Checking for new notifications... <br />
              Please wait
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
