import { Routes } from "@/constants";
import {
  Messaging_Api_EmbedTypeEnum,
  Messaging_Api_MessageEnrichedOutput,
} from "@/graphql-schema-types.generated";

export const generateNotificationHrefLink = (
  msg: Pick<Messaging_Api_MessageEnrichedOutput, "embeds" | "notification">
) => {
  const propertyId = msg.embeds?.find(
    (e) => e.embed_type === Messaging_Api_EmbedTypeEnum.EmbedTypeProperty
  )?.embed_value;

  const viewingId = msg.embeds?.find(
    (e) => e.embed_type === Messaging_Api_EmbedTypeEnum.EmbedTypeViewing
  )?.embed_value;

  const tenancyId = msg.embeds?.find(
    (e) => e.embed_type === Messaging_Api_EmbedTypeEnum.EmbedTypeTenancy
  )?.embed_value;

  // Every notification links to dashboard and should contain propertyId
  if (propertyId == null || msg.notification == null) {
    return Routes.Dashboard;
  }

  return Routes.DashboardPropertyOverview.replace("[propertyId]", propertyId)
    .replace("[viewingId]", viewingId ?? "")
    .replace("[tenancyId]", tenancyId ?? "");
};
