import React, { ComponentProps, FC } from "react";

const FullscreenOnIcon: FC<ComponentProps<"svg">> = ({ ...rest }) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10 1H14C14.5523 1 15 1.44772 15 2V6"
      stroke="#0E2C54"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M14 2L10 6"
      stroke="#0E2C54"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M6 15H2C1.44772 15 1 14.5523 1 14L1 10"
      stroke="#0E2C54"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M2 14L6 10"
      stroke="#0E2C54"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

export default FullscreenOnIcon;
