import React, { useCallback } from "react";

import { useStore } from "effector-react";

import { Close24Icon } from "@/components/icons";
import { $chatStores } from "@/core/Chat/effector";
import { ChatMode } from "@/core/Chat/effector/chat-types";
import { onModeUpdate, onSetModeHide } from "@/core/Chat/effector/mode";
import { onChannelSelected } from "@/core/Chat/effector/selected-channel";
import { Button } from "@/features/private/chat-new/components/Button";
import { Messaging_Api_ChannelScopeEnum } from "@/graphql-schema-types.generated";
import FullscreenOnIcon from "@/uikit2/assets/FullscreenOnIcon";

import { PopupHeadActionsProps } from "./types";

import styles from "./styles.module.scss";

export const PopupHeadActions = ({ onChatSelect }: PopupHeadActionsProps) => {
  const {
    $chatChannels: channels,
    $selectedChannel: selectedChannel,
    $chatMode: mode,
  } = useStore($chatStores);

  const handleClickFullscreenOn = useCallback(() => {
    onModeUpdate(ChatMode.FULL);

    if (!selectedChannel) {
      const newSelectedChannel = channels.find(
        (channel) =>
          channel.scope !== Messaging_Api_ChannelScopeEnum.NotificationScope &&
          channel.scope !== Messaging_Api_ChannelScopeEnum.UnknownChannelScope
      );

      if (newSelectedChannel) {
        onChannelSelected(newSelectedChannel.channel);
      }
    }
    if (onChatSelect) {
      onChatSelect();
    }
  }, [channels, onChatSelect, selectedChannel]);

  const handleClickClose = useCallback(() => {
    onSetModeHide();
  }, []);

  const handleClickCloseMobile = useCallback(() => {
    onSetModeHide();
  }, []);

  return (
    <>
      <Button
        className={styles.mobileButton}
        data-testid="chat-compact-mobile-close"
        onClick={handleClickCloseMobile}
      >
        <Close24Icon />
      </Button>

      {channels.length > 0 && (
        <>
          <Button
            className={styles.desktopButton}
            data-testid={
              mode === ChatMode.COMPACT
                ? "chat-compact-expand"
                : "chat-dropdown-expand"
            }
            onClick={handleClickFullscreenOn}
          >
            <FullscreenOnIcon />
          </Button>

          {mode === ChatMode.COMPACT && (
            <Button
              className={styles.desktopButton}
              data-testid="chat-compact-close"
              onClick={handleClickClose}
            >
              <Close24Icon />
            </Button>
          )}
        </>
      )}
    </>
  );
};
