import React, { useEffect, useMemo } from "react";

import cn from "classnames";
import { useStore } from "effector-react";

import { NotificationGroup } from "@/components/notifications/NotificationGroup";
import { groupMessagesByDate } from "@/components/notifications/utils";
import { useUserInfo } from "@/core/Auth/hooks";
import { $chatStores } from "@/core/Chat/effector";
import { ChatInitializedStore } from "@/core/Chat/effector/chat-types";
import { useDeleteMessage } from "@/core/Chat/hooks/use-delete-message";
import { useFetchHistory } from "@/core/Chat/hooks/use-fetch-history";
import { useMarkMessageAsSeen } from "@/core/Chat/hooks/use-mark-message-seen";
import { Icon } from "@/ui/components";

import { CHANNEL_HISTORY_FETCH_LIMIT } from "./constants";
import { MobileNotificationsPanelProps } from "./types";

import styles from "./styles.module.scss";

export const Panel = ({ onClose, isOpened }: MobileNotificationsPanelProps) => {
  const markMessagesSeen = useMarkMessageAsSeen();
  const fetchHistory = useFetchHistory();
  const deleteMessages = useDeleteMessage();
  const currentUser = useUserInfo();
  const {
    $chatUnreadSystemNotificationsCount: unreadCountNotifications,
    $chatInitialized: chatInitialized,
    $chatChannels: channels,
    $chatHistory: history,
  } = useStore($chatStores);

  const notificationChannels = useMemo(
    () => channels.filter((channel) => channel.scope === "NOTIFICATION_SCOPE"),
    [channels]
  );

  const { groupedMessagesByDate, messageGroups } = useMemo(() => {
    const groupedMessagesByDate = groupMessagesByDate({
      notificationChannels,
      history,
      showAll: true,
    });

    return {
      groupedMessagesByDate,
      messageGroups: Object.keys(groupedMessagesByDate),
    };
  }, [history, notificationChannels]);

  useEffect(() => {
    if (
      currentUser?.id &&
      chatInitialized === ChatInitializedStore.DONE &&
      isOpened
    ) {
      for (const channel of notificationChannels) {
        if (
          !history[channel.channel] ||
          history[channel.channel]!.length < unreadCountNotifications
        ) {
          void fetchHistory({
            channel: channel.channel,
            scope: channel.scope,
            limit: CHANNEL_HISTORY_FETCH_LIMIT,
          });
        }
      }
    }
  }, [
    currentUser,
    fetchHistory,
    history,
    chatInitialized,
    isOpened,
    notificationChannels,
    unreadCountNotifications,
  ]);

  return (
    <div
      className={cn(styles.root, {
        [styles["root--open"]]: isOpened,
      })}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>Notifications</h3>
        <button className={styles.close} onClick={onClose} type="button">
          <Icon height="30px" name="close" width="30px" />
        </button>
      </div>

      <div className={styles.content}>
        {messageGroups.length > 0 &&
          messageGroups.map((itemsByDate, index) => (
            <NotificationGroup
              key={itemsByDate}
              deleteMessages={deleteMessages}
              isFirst={index === 0}
              itemsByDate={itemsByDate}
              markMessagesSeen={markMessagesSeen}
              messages={groupedMessagesByDate}
              showAll
            />
          ))}

        {messageGroups.length === 0 && unreadCountNotifications === 0 && (
          <div className={styles.empty}>You don't have any notifications.</div>
        )}

        {messageGroups.length === 0 && unreadCountNotifications > 0 && (
          <div className={styles.empty}>
            Checking for new notifications...
            <br /> Please wait
          </div>
        )}
      </div>
    </div>
  );
};
