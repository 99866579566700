import React, { memo } from "react";

import Link from "next/link";

import cn from "classnames";
import format from "date-fns/format";

import { TIME_FORMAT } from "./constants";
import { NotificationItemProps } from "./types";

import styles from "./styles.module.scss";

export const NotificationItem = memo(
  ({
    markMessagesSeen: _markMessagesSeen,
    deleteMessages,
    href,
    msg,
    msgTitle,
    lastMsgDate,
    messageText,
    isHardReloadEnabled,
  }: NotificationItemProps) => (
    <div
      key={msg.id}
      className={cn(styles.root, { [styles["root--seen"]]: msg.seen })}
    >
      <div className={styles.header}>
        <div className={styles.titleBadge}>
          {href ? (
            isHardReloadEnabled ? (
              <a href={href}>{msgTitle}</a>
            ) : (
              <Link href={href}>
                <a>{msgTitle}</a>
              </Link>
            )
          ) : (
            msgTitle
          )}
        </div>

        <div className={styles.timeContainer}>
          <div className={styles.time}>{format(lastMsgDate, TIME_FORMAT)}</div>
          <button
            className={styles.clear}
            onClick={() => deleteMessages([msg])}
            type="button"
          >
            Clear
          </button>
        </div>
      </div>
      <div className={styles.content}>
        {href ? (
          isHardReloadEnabled ? (
            <a href={href}>{messageText}</a>
          ) : (
            <Link href={href}>
              <a>{messageText}</a>
            </Link>
          )
        ) : (
          messageText
        )}
      </div>
    </div>
  )
);
