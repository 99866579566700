import { useStore } from "effector-react";
import { useCallback } from "react";

import { useUserInfo } from "../../Auth/hooks";
import { $chatStores } from "../effector";
import { ChatInitializedStore } from "../effector/chat-types";
import {
  ChatSubscriptionMessageFragment,
  useNotificationsReadMutation,
} from "../graphql/index.generated";

export const useMarkMessageAsSeen = () => {
  const me = useUserInfo();
  const { $chatInitialized } = useStore($chatStores);

  const [notificationsMarkAsRead] = useNotificationsReadMutation();

  return useCallback(
    (messages: ChatSubscriptionMessageFragment[]) => {
      if ($chatInitialized !== ChatInitializedStore.DONE) {
        throw new Error("Not initialized yet");
      }

      const userId = me?.id;
      const historyMessageesIds = messages
        .filter((msg) => !Boolean(msg.seen) && !Boolean(msg.user === userId))
        .map((msg) => msg.id);

      if (historyMessageesIds.length === 0) {
        return;
      }

      void notificationsMarkAsRead({
        variables: {
          channel: messages[0].channel,
          scope: messages[0].scope,
        },
      });
    },
    [$chatInitialized, me?.id, notificationsMarkAsRead]
  );
};
