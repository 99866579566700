import { useStore } from "effector-react";
import { useCallback } from "react";

import { useUserInfo } from "../../Auth/hooks";
import { onChatNotificationsDelete } from "../effector/chat-history";
import { ChatInitializedStore } from "../effector/chat-types";
import { $chatInitialized } from "../effector/initialized";
import {
  ChatSubscriptionMessageFragment,
  useChatMessagingDeleteMutation,
} from "../graphql/index.generated";

export const useDeleteMessage = () => {
  const me = useUserInfo();
  const chatInitialized = useStore($chatInitialized);
  const [chatDeleteMessage] = useChatMessagingDeleteMutation();

  return useCallback(
    (messages: ChatSubscriptionMessageFragment[]) => {
      if (chatInitialized !== ChatInitializedStore.DONE) {
        throw new Error("Not initialized yet");
      }

      const userId = me?.id;

      if (userId != null) {
        const message = messages
          .filter((msg) => msg.user !== userId)
          .map((msg) => msg.id);

        if (message.length === 0) {
          return;
        }

        onChatNotificationsDelete({
          messagesId: message,
          channel: messages[0].channel,
        });

        void chatDeleteMessage({
          variables: {
            user: userId,
            scope: messages[0].scope,
            channel: messages[0].channel,
            message,
          },
        });
      }
    },
    [chatInitialized, me?.id, chatDeleteMessage]
  );
};
