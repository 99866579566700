import React, { Fragment } from "react";

import Link from "next/link";

import MediaQuery from "react-responsive";

import { Routes, SOCIAL_MEDIA_LINKS } from "@/constants";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from "@/features/common/components/legacy/icons";
import { ServicePageSlugCodes } from "@/lib/service-page-slug-codes";
import { screens } from "@/ui/postcss/custom-media";

import Dropdown from "./Dropdown";

import styles from "./styles.module.scss";

// TODO: Refactoring
interface LinksProps {
  items?: Array<{
    rows: Rows[];
  }>;
}
interface OurLink {
  href: string;
  title: string;
}
interface Rows {
  sectionTitle: string;
  links: OurLink[];
}

const Links: React.FC<LinksProps> = ({ items }) => (
  <MediaQuery maxWidth={screens.tabletN}>
    {(matches: boolean) =>
      matches ? (
        <nav className={styles.containerLink}>
          {items?.map((item, idx) => (
            <Fragment key={idx}>
              {item.rows.map((el, index) => (
                <Dropdown
                  key={index}
                  header={el.sectionTitle}
                  links={el.links}
                />
              ))}
            </Fragment>
          ))}
        </nav>
      ) : (
        <div className={styles.containerLink}>
          {items?.map((item, idx) => (
            <div key={idx} className={styles.rows}>
              {item.rows.map((el, index) => (
                <div key={index} className={styles.row}>
                  <div className={styles.sectionTitle}>{el.sectionTitle}</div>
                  {el.links.map((link, i) => (
                    <Link key={i} href={link.href} passHref>
                      <a className={styles.item}>{link.title}</a>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <div className={styles.socialDiv}>
            <div className={styles.social}>Follow us</div>
            <a
              href={SOCIAL_MEDIA_LINKS.facebook}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookIcon />
              <span>Facebook</span>
            </a>
            <a
              href={SOCIAL_MEDIA_LINKS.instagram}
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon />
              <span>Instagram</span>
            </a>
            <a
              href={SOCIAL_MEDIA_LINKS.twitter}
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterIcon />
              <span>Twitter</span>
            </a>
          </div>
        </div>
      )
    }
  </MediaQuery>
);

const defaultProps: LinksProps = {
  items: [
    {
      rows: [
        {
          sectionTitle: "Company",
          links: [
            {
              title: "About",
              href: Routes.AboutUs,
            },
            {
              title: "Pricing",
              href: "/pricing",
            },
            {
              title: "Blog",
              href: "/news/all",
            },
            {
              title: "Area guides",
              href: "/area-guides",
            },
            {
              title: "FAQ",
              href: "/faq",
            },
            {
              title: "Help Centre",
              href: Routes.Helpcentre,
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          sectionTitle: "For landlords",
          links: [
            {
              title: "List your property",
              href: "/listing",
            },
            {
              title: "Gas Safety",
              href: "/services/gas-safety",
            },
            {
              title: "EPC",
              href: "/services/epc",
            },
            {
              title: "EICR",
              href: "/services/eicr",
            },
            {
              title: "Inventory, check-in & check-out",
              href: "/services/inventory",
            },
            {
              title: "Professional Photography",
              href: "/services/property-photography",
            },
            {
              title: "Tenant referencing",
              href: "/services/tenant-referencing",
            },
            {
              title: "Maintenance management",
              href: "/services/maintenance",
            },
            {
              title: "Tenant team up",
              href: "/tenant-team-up",
            },
            {
              title: "Increased Rental Security",
              href: "/services/deposit-replacement-landlord",
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          sectionTitle: "Tools",
          links: [
            {
              title: "Market valuation",
              href: "/products/market-valuation",
            },
            {
              title: "Certificate repository",
              href: "/products/certificate",
            },
            {
              title: "Expense tracker",
              href: "/products/expense-tracker",
            },
          ],
        },
        {
          sectionTitle: "For tenants",
          links: [
            {
              title: "Browse homes",
              href: "/to-rent",
            },
            {
              title: "Make money when you move",
              href: "/tenant",
            },
            {
              title: "Deposit replacement",
              href: "/services/deposit-replacement-tenant",
            },
            {
              title: "Contents insurance",
              href: `/services/${ServicePageSlugCodes.ContentsInsurance}`,
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          sectionTitle: "Insurance",
          links: [
            {
              title: "Contents insurance",
              href: `/services/${ServicePageSlugCodes.ContentsInsurance}`,
            },
            {
              title: "Home emergency insurance",
              href: `/services/${ServicePageSlugCodes.HomeEmergencyInsurance}`,
            },
            {
              title: "Rent guarantee insurance",
              href: `/services/${ServicePageSlugCodes.RentGuaranteeInsurance}`,
            },
          ],
        },
        {
          sectionTitle: "Mortgages",
          links: [
            {
              title: "Remortgage",
              href: "/remortgage",
            },
            {
              title: "Buy to let mortgages",
              href: "/buy-to-let",
            },
            {
              title: "First time buyer",
              href: "/first-time-buyer",
            },
          ],
        },
      ],
    },
  ],
};

Links.defaultProps = defaultProps;

export default Links;
