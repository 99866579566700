import {
  Messaging_Api_MessageEnrichedOutput,
  Notification_Api_EntityEnum,
} from "@/graphql-schema-types.generated";

import { generateNotificationHrefLink } from "../../utils";

export const getNotificationItemProps = (
  message: Messaging_Api_MessageEnrichedOutput
) => {
  let messageText = message.text;
  let isHardReloadEnabled = false;

  if (
    message.notification?.entity ===
    Notification_Api_EntityEnum.EntityPropertyViewingBroadcasting
  ) {
    const manager = message.embeds?.find(
      (e) => e.embed_type === "EMBED_TYPE_PROPERTY"
    )?.property?.people.manager;

    if (manager) {
      messageText = `${manager.details?.first_name} ${manager.details?.last_name} started a video viewing. Click here to join.`;
    }

    isHardReloadEnabled = true;
  }

  return {
    href: generateNotificationHrefLink(message),
    isHardReloadEnabled,
    lastMsgDate: message.created ? new Date(message.created) : new Date(),
    messageText,
    msg: message,
    msgTitle: message.notification?.title ?? "New notification",
  };
};
