import React, { FC } from "react";

import { TextProps } from "./types";

import styles from "./styles.module.scss";

export const Text: FC<TextProps> = ({
  children,
  element = "div",
  variant = "title",
}) =>
  React.createElement(
    element,
    {
      className: styles[variant],
    },
    [children]
  );
