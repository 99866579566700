import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import cn from "classnames";
import { useStore } from "effector-react";

import { NotificationActions } from "@/components/notifications/NotificationActions";
import { Button } from "@/components/ui";
import { useIsUserDataLoading, useUserInfo } from "@/core/Auth/hooks";
import { ChatInitializedStore } from "@/core/Chat/effector/chat-types";
import { $chatInitialized } from "@/core/Chat/effector/initialized";
import { AuthUrlService } from "@/lib/services";
import { Button as OldButton } from "@/ui/components";

import { AccountDropdown } from "../AccountDropdown";
import { ChatActions } from "../ChatActions";
import { UserActionsSkeleton } from "../UserActionsSkeleton";
import { UserActionsProps } from "./types";

import styles from "./styles.module.scss";

export const UserActions = ({ isNavigationOpened }: UserActionsProps) => {
  const chatInitialized = useStore($chatInitialized);
  const me = useUserInfo();
  const loading = useIsUserDataLoading();
  const router = useRouter();

  if (loading) {
    return (
      <div className={styles.skeleton}>
        <UserActionsSkeleton />
      </div>
    );
  }

  return (
    <>
      {me?.id == null ? (
        <>
          <div
            className={cn(styles.userActions, styles.userActionsDesktop, {
              [styles.smallZIndex]: isNavigationOpened,
            })}
          >
            <Link href={AuthUrlService.getLoginUrl(router?.asPath)}>
              <a className={styles.signInButton} rel="nofollow">
                <OldButton variant="light">Sign in</OldButton>
              </a>
            </Link>
            <Button
              as="a"
              nextLinkProps={{
                href: AuthUrlService.getCreateAccountUrl(router?.asPath),
              }}
              rel="nofollow"
              shape="rounded"
              size="md"
              theme="primary"
            >
              Join Mashroom
            </Button>
          </div>
          <div className={styles.userActionsMobile}>
            <AccountDropdown
              avatar={me?.details?.avatar?.id}
              firstName={me?.details?.first_name}
            />
          </div>
        </>
      ) : (
        <div
          className={cn(styles.userActions, {
            [styles.smallZIndex]: isNavigationOpened,
          })}
        >
          {chatInitialized === ChatInitializedStore.DONE && (
            <div className={styles.messagesButtons}>
              <NotificationActions />
              <ChatActions />
            </div>
          )}
          <AccountDropdown
            avatar={me.details?.avatar?.id}
            firstName={me.details?.first_name}
          />
        </div>
      )}
    </>
  );
};
