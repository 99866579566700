import React from "react";

import { useUserInfo } from "@/core/Auth/hooks";

import { Panel } from "./Panel";
import { MobileNotificationsProps } from "./types";

export const MobileNotifications = ({
  isOpened,
  onClose,
}: MobileNotificationsProps) => {
  const currentUser = useUserInfo();

  if (!currentUser?.id) {
    return null;
  }

  return <Panel isOpened={isOpened} onClose={onClose} />;
};
