import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import cn from "classnames";
import { formatDistanceToNow, isDate } from "date-fns";
import { useStore } from "effector-react";

import { $chatPresence } from "@/core/Chat/effector/presense";
import useWhoAmI, {
  WhoAmI,
} from "@/features/private/dashboard/hooks/useIsTenant";
import { Messaging_Api_PresenceEnum } from "@/graphql-schema-types.generated";
import propertyAddressFilter from "@/lib/formatters/property-address";
import { hasData } from "@/lib/remote";
import { getMediaUrl } from "@/lib/utils";

import { Avatar } from "../Avatar";
import { TIME_INTERVAL } from "./constants";
import {
  useUserChatAccountInfoQuery,
  useUserChatPropertyLocationQuery,
} from "./graphql/queries.generated";
import { UserProps } from "./types";

import styles from "./styles.module.scss";

export const User: FC<UserProps> = ({
  className,
  userId,
  propertyId,
  lastMessageDate,
  ...rest
}) => {
  const [lastMsgTime, setTimeOfLastMsg] = useState<null | string>();
  const presence = useStore($chatPresence);

  const userRest = useUserChatAccountInfoQuery({
    variables: {
      id: userId,
    },
    skip: userId == null,
  });

  const whoAmI = useWhoAmI(propertyId);
  const hideFlatAndBuilding = React.useMemo(() => {
    if (!hasData(whoAmI)) {
      return true;
    }

    if (propertyId) {
      return whoAmI.data === WhoAmI.Tenant || whoAmI.data === WhoAmI.Applicant;
    }

    return false;
  }, [propertyId, whoAmI]);

  const { data, loading } = useUserChatPropertyLocationQuery({
    variables: {
      id: propertyId,
    },
    skip: !propertyId,
  });

  const setTime = useCallback(() => {
    lastMessageDate &&
      setTimeOfLastMsg(
        formatDistanceToNow(new Date(lastMessageDate), {
          addSuffix: false,
          includeSeconds: false,
        })
      );
  }, [lastMessageDate]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null;

    if (lastMessageDate && isDate(new Date(lastMessageDate))) {
      setTime();
      timer = setInterval(setTime, TIME_INTERVAL);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [lastMessageDate, setTime]);

  const account = userRest.data?.account?.whois;

  const avatarImage = useMemo(
    () =>
      account?.details?.avatar?.id
        ? getMediaUrl(account.details.avatar.id)
        : undefined,
    [account]
  );

  const isOnline = useMemo(() => {
    const userPresenceInfo = presence.find((p) => p.user === account?.id);

    return (
      userPresenceInfo?.presence === Messaging_Api_PresenceEnum.PresenceOnline
    );
  }, [account, presence]);

  if (!account) {
    return (
      <div className={cn(styles.container, className)} {...rest}>
        <div className={styles.avatarDisabled} />
        <div className={styles.content}>
          <div className={styles.contentBody}>
            <div className={styles.nameDisabled} />
            <div className={styles.addressDisabled} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.container, className)} {...rest}>
      <Avatar
        avatarImage={avatarImage}
        firstName={account.details?.first_name}
        isOnline={isOnline}
        lastName={account.details?.last_name}
        userId={account.id}
      />
      <div className={styles.content}>
        <div className={styles.contentBody}>
          <div className={styles.name}>
            {`${account.details?.first_name} ${account.details?.last_name}`}
          </div>

          {loading && <div className={styles.addressDisabled} />}

          {!loading && data?.property?.get?.property.location && (
            <div className={styles.address}>
              {propertyAddressFilter(
                data.property.get.property,
                hideFlatAndBuilding
              )}
            </div>
          )}
        </div>
        {lastMsgTime && (
          <div className={styles.aside}>
            <div className={styles.time}>{lastMsgTime}</div>
          </div>
        )}
      </div>
    </div>
  );
};
