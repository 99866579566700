import React, { FC, useMemo } from "react";

import cn from "classnames";

import { useUserInitials } from "@/hooks";

import { AvatarProps } from "./types";

import styles from "./styles.module.scss";

export const Avatar: FC<AvatarProps> = ({
  avatarImage,
  className,
  firstName,
  isOnline,
  lastName,
  style,
  userId,
  ...rest
}) => {
  const bgColorFromId = useMemo(() => {
    if (userId) {
      const splitId = userId.split("-");

      return Number(Number.parseInt(splitId[splitId.length - 1], 16));
    }

    return Number(Number.parseInt("123", 16));
  }, [userId]);

  const bgColorStyle = useMemo(
    () => ({
      backgroundColor: `hsl(${(bgColorFromId % 36) * 10}, 50%, 65%)`,
    }),
    [bgColorFromId]
  );

  const initials = useUserInitials({ firstName, lastName });

  return (
    <div
      className={cn(styles.root, className)}
      style={{
        ...bgColorStyle,
        ...style,
      }}
      {...rest}
    >
      {avatarImage ? (
        <img
          alt={`${firstName} ${lastName}`}
          className={styles.img}
          src={avatarImage}
        />
      ) : (
        <div className={styles.initials}>{initials}</div>
      )}

      {isOnline && <div className={styles.badge} />}
    </div>
  );
};
