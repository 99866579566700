import { useCallback } from "react";

import { useStore } from "effector-react";

import {
  ChatInitializedStore,
  CoreMessagingHistoryMutationVariables,
} from "../effector/chat-types";
import { $chatInitialized } from "../effector/initialized";
import { useChatMessagingHistoryMutation } from "../graphql/index.generated";

export const useFetchHistory = () => {
  const chatInitialized = useStore($chatInitialized);
  const [chatFetchHistory] = useChatMessagingHistoryMutation();

  return useCallback(
    (variables: CoreMessagingHistoryMutationVariables) => {
      if (chatInitialized !== ChatInitializedStore.DONE) {
        throw new Error("Not initialized yet");
      }

      return chatFetchHistory({
        variables,
      });
    },
    [chatFetchHistory, chatInitialized]
  );
};
