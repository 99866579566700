import React, { FC, HTMLAttributes, ReactNode } from "react";

import cn from "classnames";

import { ChatMode } from "@/core/Chat/effector/chat-types";

import styles from "./styles.module.scss";

export const LayoutFrame: FC<{
  children: ReactNode | ReactNode[];
  mode: ChatMode;
  bot: boolean;
}> = ({ children, mode, bot }) => (
  <div
    className={cn(
      styles.frame,
      styles[`frame--${mode.toLocaleLowerCase()}`],
      bot && styles["frame--bot"]
    )}
    hidden={mode === ChatMode.HIDDEN}
  >
    {children}
  </div>
);

export const LayoutFullViewContainer: FC<{
  children: ReactNode | ReactNode[];
  mode: ChatMode;
}> = ({ children, mode }) => (
  <div
    className={cn(
      styles.fullViewContainer,
      styles[`fullViewContainer--${mode.toLocaleLowerCase()}`]
    )}
  >
    {children}
  </div>
);

export const LayoutFullViewHeader: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => <div className={styles.fullViewHeader}>{children}</div>;

export const LayoutFullViewContent: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => <div className={styles.fullViewContent}>{children}</div>;

export const LayoutFullViewSidebar: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => <div className={styles.fullViewSidebar}>{children}</div>;

export const LayoutFullViewMessages: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => (
  <div className={styles.fullViewMessages}>{children}</div>
);

export const LayoutPopup: FC<{
  children: ReactNode | ReactNode[];
  mode: ChatMode;
}> = ({ children, mode }) => (
  <div
    className={cn(styles.popup, styles[`popup--${mode.toLocaleLowerCase()}`])}
  >
    {children}
  </div>
);

interface ElementProps extends HTMLAttributes<HTMLDivElement> {
  callback?: ReactNode;
}

export const LayoutOverlay: FC<ElementProps> = ({ children, ...rest }) => (
  <div className={styles.overlay} {...rest}>
    {children}
  </div>
);

export const LayoutHeader: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => <div className={styles.header}>{children}</div>;

export const LayoutHeaderContent: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => <div className={styles.headerContent}>{children}</div>;

export const LayoutHeaderAside: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => <div className={styles.headerAside}>{children}</div>;

export const LayoutNoMessages: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => <div className={styles.noMessages}>{children}</div>;

export const LayoutPopupContent: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => (
  <div className={cn(styles.popupContent, styles.overflow)}>{children}</div>
);

type HTMLProps = React.HTMLProps<HTMLDivElement>;

export const LayoutPopupMessageContainer = React.forwardRef<
  HTMLDivElement,
  HTMLProps
>(({ children, ...rest }, ref) => (
  <div ref={ref} className={styles.popupMessageContainer} {...rest}>
    {children}
  </div>
));

export const LayoutPopupMessageContent = React.forwardRef<
  HTMLDivElement,
  HTMLProps
>(({ children, ...rest }, ref) => (
  <div
    ref={ref}
    className={cn(styles.popupMessageContent, styles.overflow)}
    {...rest}
  >
    {children}
  </div>
));

export const LayoutPopupContentInner: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => (
  <div className={styles.popupContentInner}>{children}</div>
);

export const LayoutChannels: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => (
  <div className={cn(styles.channels, styles.overflow)}>{children}</div>
);

export const LayoutChannelsTitle: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => <div className={styles.channelsTitle}>{children}</div>;

export const LayoutMessages: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => <div className={styles.messages}>{children}</div>;

export const LayoutDetails: FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => (
  <div className={cn(styles.details, styles.overflow)}>{children}</div>
);
