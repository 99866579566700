import React from "react";

import { useStore } from "effector-react";

import { $chatStores } from "@/core/Chat/effector";
import { ChatInitializedStore } from "@/core/Chat/effector/chat-types";
import Loading from "@/uikit2/atoms/Loading";

import { ChannelsList } from "../../ChannelsList";
import {
  LayoutNoMessages,
  LayoutPopupContent,
  LayoutPopupContentInner,
} from "../../Layout";
import { Text } from "../../Text";
import { DropdownViewContentProps } from "./types";

export const Content = ({ onChatSelect }: DropdownViewContentProps) => {
  const {
    $chatChannels: channels,
    $chatInitialized: chatInitialized,
    $chatError: error,
  } = useStore($chatStores);

  if (chatInitialized !== ChatInitializedStore.DONE) {
    return (
      <LayoutNoMessages>
        <Loading />
      </LayoutNoMessages>
    );
  }

  if (channels.length === 0) {
    return (
      <LayoutNoMessages>
        <Text variant="info">
          {error ?? "You haven't got any chat conversations yet"}
        </Text>
      </LayoutNoMessages>
    );
  }

  return (
    <LayoutPopupContent>
      <LayoutPopupContentInner>
        <ChannelsList onChatSelect={onChatSelect} />
      </LayoutPopupContentInner>
    </LayoutPopupContent>
  );
};
