import React from "react";

import { LayoutHeader, LayoutHeaderContent } from "../Layout";
import { PopupHeadActions } from "../PopupHeadActions";
import { Text } from "../Text";
import { Content } from "./Content";
import { DropdownViewProps } from "./types";

export const DropdownView = ({ onChatSelect }: DropdownViewProps) => (
  <>
    <LayoutHeader>
      <LayoutHeaderContent>
        <Text variant="title">Chats</Text>
      </LayoutHeaderContent>
      <PopupHeadActions onChatSelect={onChatSelect} />
    </LayoutHeader>
    <Content onChatSelect={onChatSelect} />
  </>
);
