import React, { memo, useMemo } from "react";

import format from "date-fns/format";

import { Messaging_Api_MessageEnrichedOutput } from "@/graphql-schema-types.generated";

import { NotificationItem } from "../NotificationItem";
import { GROUP_DATE_FORMAT, TODAY_FORMAT } from "./constants";
import { NotificationGroupProps } from "./types";
import { getNotificationItemProps } from "./utils/getNotificationItemProps";

import styles from "./styles.module.scss";

export const NotificationGroup = memo(
  ({
    itemsByDate,
    markMessagesSeen,
    deleteMessages,
    toggleDropdown,
    messages,
    showAll = false,
    isFirst,
  }: NotificationGroupProps) => {
    const filteredMessages = useMemo(() => {
      const messagesByDate = messages[itemsByDate] ?? [];

      return (
        showAll
          ? messagesByDate
          : messagesByDate.filter((message) => !message.seen)
      ) as Messaging_Api_MessageEnrichedOutput[];
    }, [itemsByDate, messages, showAll]);

    const isToday = useMemo(
      () => itemsByDate === format(new Date(), TODAY_FORMAT),
      [itemsByDate]
    );

    if (filteredMessages.length === 0) {
      return null;
    }

    const lastMessageDate = new Date(filteredMessages[0].created);

    return (
      <div>
        <div className={styles.header}>
          <div className={styles.title}>
            {isToday ? <>Today</> : format(lastMessageDate, GROUP_DATE_FORMAT)}
          </div>
          <div className={styles.actions}>
            {isFirst && (
              <div className={styles.actionsItem}>
                <button
                  className={styles.actionButton}
                  onClick={() => {
                    markMessagesSeen(filteredMessages);
                    toggleDropdown?.();
                  }}
                  type="button"
                >
                  Mark all as read
                </button>
              </div>
            )}
            <div className={styles.actionsItem}>
              <button
                className={styles.actionButton}
                onClick={() => deleteMessages(filteredMessages)}
                type="button"
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
        {filteredMessages.map((message) => (
          <NotificationItem
            {...getNotificationItemProps(message)}
            key={message.id}
            deleteMessages={deleteMessages}
            markMessagesSeen={markMessagesSeen}
          />
        ))}
      </div>
    );
  }
);
