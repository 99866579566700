import React, { useMemo } from "react";

import cn from "classnames";
import { useStore } from "effector-react";

import { useUserInfo } from "@/core/Auth/hooks";
import { ChatMode } from "@/core/Chat/effector/chat-types";
import { $chatMode, onSetModeCompact } from "@/core/Chat/effector/mode";
import {
  $selectedChannel,
  onChannelSelected,
} from "@/core/Chat/effector/selected-channel";
import { Messaging_Api_EmbedTypeEnum } from "@/graphql-schema-types.generated";

import { Text } from "../Text";
import { User } from "../User";
import { ChannelProps } from "./types";

import styles from "./styles.module.scss";

export const Channel = ({
  channel: initialChannel,
  onChatSelect,
}: ChannelProps) => {
  const { last_message, channel } = initialChannel;
  const embeds = useMemo(
    () => initialChannel.embeds ?? [],
    [initialChannel.embeds]
  );
  const currentUser = useUserInfo();
  const mode = useStore($chatMode);
  const selectedChannel = useStore($selectedChannel);

  const handleClickChannel = () => {
    if (mode === ChatMode.HIDDEN) {
      if (onChatSelect) {
        onChatSelect();
      }

      onSetModeCompact();
      onChannelSelected(channel);

      return;
    }

    if (mode === ChatMode.PREVIEW) {
      onSetModeCompact();
      onChannelSelected(channel);

      return;
    }

    onChannelSelected(channel);
  };

  const userId = useMemo(
    () =>
      embeds.find(
        (embed) =>
          embed.embed_type === Messaging_Api_EmbedTypeEnum.EmbedTypeUser &&
          embed.embed_value !== currentUser?.id
      )?.embed_value,
    [embeds, currentUser]
  );

  const propertyId = useMemo(
    () =>
      embeds.find(
        (embed) =>
          embed.embed_type === Messaging_Api_EmbedTypeEnum.EmbedTypeProperty
      )?.embed_value,
    [embeds]
  );

  return (
    <div
      className={cn(styles.item, {
        [styles.itemSelected]: channel === selectedChannel,
      })}
      data-testid={
        mode === ChatMode.FULL
          ? "chat-fullscreen-select-channel"
          : "chat-dropdown-select-channel"
      }
      onClick={handleClickChannel}
      role="presentation"
    >
      <User
        lastMessageDate={last_message.created}
        propertyId={propertyId}
        userId={userId}
      />

      {last_message.text && (
        <Text
          variant={
            last_message.seen || last_message.user === currentUser?.id
              ? "message-read"
              : "message-unread"
          }
        >
          {last_message.text}
        </Text>
      )}
    </div>
  );
};
