import React, { useMemo } from "react";

import { useStore } from "effector-react";

import { $chatChannels } from "@/core/Chat/effector/channels";
import { Messaging_Api_ChannelScopeEnum } from "@/graphql-schema-types.generated";

import { Channel } from "../Channel";
import { ChannelsListProps } from "./types";

export const ChannelsList = ({ onChatSelect }: ChannelsListProps) => {
  const channels = useStore($chatChannels);
  const filteredChannelsByScope = useMemo(
    () =>
      channels.filter(
        (channel) =>
          channel.scope !== Messaging_Api_ChannelScopeEnum.NotificationScope &&
          channel.scope !== Messaging_Api_ChannelScopeEnum.UnknownChannelScope
      ),
    [channels]
  );

  return (
    <>
      {filteredChannelsByScope.map((channel) => (
        <Channel
          key={channel.channel}
          channel={channel}
          onChatSelect={onChatSelect}
        />
      ))}
    </>
  );
};
