import { useUserInfo } from "../../../../core/Auth/hooks";
import { remoteMap, toRemoteData } from "../../../../lib/remote";
import {
  IsTenantPeopleFragment,
  useUseIsTenantQuery,
} from "./useIsTenant.generated";

export enum WhoAmI {
  Tenant = "Tenant",
  TenantManager = "Tenant-manager",
  Landlord = "Landlord",
  Manager = "Manager",
  Submitter = "Submitter",
  Applicant = "Applicant",
}

export default function useWhoAmI(propertyId: string | null | undefined) {
  const auth = useUserInfo();
  const result = useUseIsTenantQuery({
    variables: { id: propertyId! },
    skip: propertyId == null || auth?.id == null,
  });
  const data = toRemoteData(result);
  return remoteMap((data) => {
    const people = data.property?.get?.property.people;

    return fetchWhoAmI(people, auth?.id);
  }, data);
}

export const fetchWhoAmI = (
  people: IsTenantPeopleFragment | undefined,
  myId: string | undefined | null
): WhoAmI =>
  /**
   * кем может быть юзер
   * 1 простой тенант
   * 2 тенант-менеджер
   * 3 лендлорд
   * 4 лендлорд-менеджер
   * 5 менеджер
   * 6 сабмиттер - тот, кто засабмитил проперти
   * 7 хз
   */
  people
    ? people.tenants.some(
        (tenant) =>
          tenant.user?.id === myId &&
          tenant.active !== false &&
          people.manager?.id !== myId
      )
      ? WhoAmI.Tenant
      : people.tenants.some(
          (tenant) =>
            tenant.user?.id === myId &&
            tenant.active !== false &&
            people.manager?.id === myId
        )
      ? WhoAmI.TenantManager
      : people.landlord?.id === myId
      ? WhoAmI.Landlord
      : people.manager?.id === myId
      ? WhoAmI.Manager
      : people.submitter?.id === myId
      ? WhoAmI.Submitter
      : WhoAmI.Applicant
    : WhoAmI.Applicant;
